import { InvoiceBillingType } from '../../store/pay-invoice/payInvoiceReducer';

export function printInvoiceBillingType(billingType: InvoiceBillingType) {
    switch (billingType) {
        case 'PAY_PER_USE':
            return 'Pay-per-Use Rechnung';
        case 'PAY_PER_USE_VOUCHER':
            return 'Pay-per-Use Rechnung, mit Voucher/Gutschein bezahlt.';
        default:
            return undefined;
    }
}
