import React, { FunctionComponent, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import UserInformationTable from '../../components/user-information-table/UserInformationTable';
import {
    InvoicePaymentStatus,
    ManageUserStore,
    PaymentMethod,
    PaymentMethodStatus,
    PaymentMethodType,
    TransactionStatus,
} from '../../store/manage-user/manageUserReducer';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    UserAccountState,
    UserExtendedInformation,
    UserType,
} from '../../store/search-user/searchUserReducer';
import {
    deleteUser,
    forceUpdateUserRealtimeDataMethod,
    resendActivationCode,
    resetUserActionStatus,
    revalidatePaymentMethod,
    selectPaymentMethod,
    sendPasswordResetEmail,
    undeleteUser,
} from '../../store/manage-user/manageUserActions';
import { ModalParameters, openModal } from '../../store/modal/modalActions';
import { ModalType } from '../modal/RootModal';
import moment from 'moment';
import { Route } from '../../Router';
import Button from 'react-bootstrap/Button';
import { goTo } from '../../store/router/routerActions';
import AddPayment from '../../forms/manage-payments/AddPayment';
import styles from './ManageUser.module.css';
import classnames from 'classnames';
import { formatMonetaryAmount } from '../../store/amountAndCurrency';
import ErrorFeedback from '../../forms/ErrorFeedback';
import UserReservationList from './UserReservationList';
import UserActionButton from './UserActionButton';
import UserInformationCard from './elements/UserInformationCard';
import { InvoiceBillingType } from '../../store/pay-invoice/payInvoiceReducer';
import { printInvoiceBillingType } from '../../forms/refund-reservation/InvoiceUtil';
import { renderPaymentMethodStatus } from './elements/RenderPaymentMethodStatusUtil';

interface Props {
    user: ManageUserStore;
    searchResults: UserExtendedInformation[];
    searchResultsById: UserExtendedInformation[];
    selectPaymentMethod: (paymentMethodId: number, userId: number) => any;
    validatePaymentMethod: (paymentMethodId: number, userId: number) => any;
    openModal: (params: ModalParameters<any>) => any;
    goTo: (route: Route, param?: string | number) => void;
    isAdmin: boolean;
    forceUpdateUserRealtimeDataMethod: (userId: number) => void;
    deleteUser: (userId: number, callback?: (success: boolean) => void) => any;
    undeleteUser: (userId: number) => void;
    resendActivationCode: (userId: number) => void;
    sendPasswordResetEmail: (userId: number) => void;
    resetUserActionStatus: () => void;
}

const ManageUser: FunctionComponent<Props> = ({
    user,
    searchResults,
    searchResultsById,
    selectPaymentMethod,
    validatePaymentMethod,
    openModal,
    goTo,
    isAdmin,
    forceUpdateUserRealtimeDataMethod,
    deleteUser,
    undeleteUser,
    resetUserActionStatus,
    resendActivationCode,
    sendPasswordResetEmail,
}) => {
    function handleSelect(paymentMethod: PaymentMethod, userId: number) {
        let paymentMethodStatus = paymentMethod.status;
        if (
            [
                PaymentMethodStatus.TRIAL,
                PaymentMethodStatus.UNVERIFIED,
                PaymentMethodStatus.VERIFIED,
                PaymentMethodStatus.TRUSTED,
            ].includes(paymentMethodStatus)
        ) {
            selectPaymentMethod(paymentMethod.id, userId);
        } else {
            openModal({
                type: ModalType.FAILED_PAYMENT_SELECTION_INFORMATION,
                data: { paymentMethodStatus },
            });
        }
    }

    function handleDeletePaymentMethod(
        paymentMethod: PaymentMethod,
        userId: number
    ) {
        let paymentMethodStatus = paymentMethod.status;
        let paymentMethodId = paymentMethod.id;
        if (
            paymentMethod.selected ||
            [
                PaymentMethodStatus.TRIAL,
                PaymentMethodStatus.UNVERIFIED,
                PaymentMethodStatus.INVALID,
                PaymentMethodStatus.NEW,
            ].includes(paymentMethodStatus)
        ) {
            openModal({
                type: ModalType.FAILED_PAYMENT_DELETION_INFORMATION,
                data: { paymentMethod },
            });
        } else {
            openModal({
                type: ModalType.DELETE_PAYMENT_CONFIRMATION,
                data: { paymentMethodId, userId },
            });
        }
    }

    function handleValidate(paymentMethod: PaymentMethod, userId: number) {
        let paymentMethodStatus = paymentMethod.status;
        let paymentMethodId = paymentMethod.id;

        if (
            paymentMethodStatus === PaymentMethodStatus.TRIAL ||
            paymentMethodStatus === PaymentMethodStatus.UNVERIFIED
        ) {
            validatePaymentMethod(paymentMethodId, userId);
        } else {
            openModal({
                type: ModalType.FAILED_PAYMENT_VERIFICATION_INFORMATION,
                data: { paymentMethodStatus },
            });
        }
    }

    useEffect(() => {
        resetUserActionStatus && resetUserActionStatus();
    }, [resetUserActionStatus, searchResultsById]);

    if (!(searchResultsById.length > 0 && user && user.details)) {
        return null;
    }

    function translateFinancialTransactionType(type: string) {
        switch (type) {
            case 'INVOICE':
                return 'Rechnung';
            case 'TOP_UP':
                return 'Gutschrift';
            case 'DEBT_COLLECTION':
                return 'Schuldenzahlung';
            default:
                return type;
        }
    }

    function translatePaymentInformation(paymentInformation: string) {
        if (paymentInformation === 'MANUAL_TRANSFER') {
            return 'Manuelle Überweisung';
        }
        return paymentInformation;
    }

    function translateFinancialTransactionStatus(status: TransactionStatus) {
        switch (status) {
            case TransactionStatus.FAILED:
            case TransactionStatus.FAILED_REFUND:
                return 'Fehler';
            case TransactionStatus.NEW:
            case TransactionStatus.OPEN_REFUND:
                return 'In Bearbeitung';
            case TransactionStatus.REFUND_SETTLED:
            case TransactionStatus.REFUNDED:
            case TransactionStatus.PAYMENT_SETTLED:
            case TransactionStatus.SUCCESS:
                return 'Erfolg';
            default:
                return status;
        }
    }

    function translateInvoicePaymentStatus(status: InvoicePaymentStatus) {
        switch (status) {
            case InvoicePaymentStatus.FAILED:
                return 'Fehlgeschlagen';
            case InvoicePaymentStatus.OPEN:
                return 'Offen';
            case InvoicePaymentStatus.WAITING_FOR_EXTERNAL_CHECKOUT:
                return 'Mollie Checkout nicht abgeschlossen';
            default:
                return status;
        }
    }

    function handleChangeLaundryRoom() {
        openModal({
            type: ModalType.CHANGE_LAUNDRY_ROOM_WITH_LIST_ACTION,
            data: {
                userId: user_id,
                email: information.email,
                locationId: String(information.location.id),
            },
        });
    }

    function handleChangeLaundryRoomById() {
        openModal({
            type: ModalType.CHANGE_LAUNDRY_ROOM_ACTION,
            data: { userId: user_id, email: information.email },
        });
    }

    function handleEditInvoiceDelivery() {
        openModal({
            type: ModalType.CHANGE_INVOICE_DELIVERY,
            data: { userId: user_id },
        });
    }

    function handleEditAllowSepaMandateCreation() {
        openModal({
            type: ModalType.CHANGE_ALLOW_SEPA_MANDATE_CREATION,
            data: { userId: user_id },
        });
    }

    function handleEditParallelReservations() {
        openModal({
            type: ModalType.CHANGE_USER_PARALLEL_RESERVATIONS,
            data: { userId: user_id },
        });
    }

    function handleDeleteUser() {
        const userName = information.name;
        const userId = user_id;
        if (
            window.confirm(
                `Möchten Sie den Benutzer mit ID ${userId} wirklich löschen?`
            )
        ) {
            deleteUser(userId, handleDeleteUserFinished);
        }

        function handleDeleteUserFinished(success: boolean) {
            if (success) {
                window.alert(
                    `Benutzer "${userName}" mit ID ${userId} erfolgreich gelöscht.`
                );
            }
        }
    }

    function handleUndeleteUser() {
        const userId = user_id;
        if (
            window.confirm(
                `Möchten Sie die Löschung des Benutzers mit ID ${userId} wirklich widerrufen?`
            )
        ) {
            undeleteUser(userId);
        }
    }

    async function handleForceRealtimeUpdate() {
        await forceUpdateUserRealtimeDataMethod(user_id);
    }

    function renderSelectionInfo(it: PaymentMethod) {
        if (it.selected) {
            return 'Ausgewählte Zahlungsmethode';
        } else if (it.valid_for_selection) {
            return (
                <Button
                    size="sm"
                    onClick={() => handleSelect(it, user_id)}
                    disabled={user.loading}
                >
                    WÄHLEN
                </Button>
            );
        } else if (
            [PaymentMethodStatus.INVALID, PaymentMethodStatus.EXPIRED].includes(
                it.status
            )
        ) {
            return '';
        } else {
            return 'Nicht verfügbar';
        }
    }

    function renderPaymentMethod(it: PaymentMethod) {
        const shouldHaveActions = it.type !== PaymentMethodType.checkout;
        return (
            <tr key={it.id}>
                <td>{it.type}</td>
                <td>{it.payment_info}</td>
                <td>{it.valid_until}</td>
                <td>{renderPaymentMethodStatus(it)}</td>
                {isAdmin && <td>{it.failure_rate_percent} %</td>}
                <td>{renderSelectionInfo(it)}</td>
                {isAdmin && (
                    <td>
                        {shouldHaveActions && (
                            <Button
                                size="sm"
                                onClick={() =>
                                    handleDeletePaymentMethod(it, user_id)
                                }
                                disabled={disallowed}
                            >
                                LÖSCHEN
                            </Button>
                        )}
                    </td>
                )}
                {isAdmin && (
                    <td>
                        {shouldHaveActions && (
                            <Button
                                size="sm"
                                onClick={() => handleValidate(it, user_id)}
                                disabled={disallowed}
                            >
                                VALIDIEREN
                            </Button>
                        )}
                    </td>
                )}
            </tr>
        );
    }

    function handleNewActivationCode() {
        resendActivationCode(user_id);
    }

    function handlePasswordResetEmail() {
        sendPasswordResetEmail(user_id);
    }

    function handleShowMoreInvoices() {
        goTo(Route.MANAGE_INVOICES_OF_USER, user_id);
    }

    function handleShowMoreReservations() {
        goTo(Route.MANAGE_RESERVATIONS_OF_USER, user_id);
    }

    function handlePhoneUserUpdateEmail() {
        openModal({
            type: ModalType.CHANGE_PHONE_USER_EMAIL,
            data: { userId: user_id, email: information.email },
        });
    }

    function renderUserMainActions() {
        const isNewAccount =
            UserAccountState.ACTIVATING === information.account_state;
        const isDeleteAccount =
            UserAccountState.DELETE === information.account_state;
        const isPhoneUser = UserType.PHONE === information.user_type;

        let actionStuff;
        if (isNewAccount) {
            actionStuff = {
                handler: handleNewActivationCode,
                text: 'Neuen Aktivierungscode schicken',
                successText: 'Aktivierungscode verschickt',
            };
        } else if (isDeleteAccount) {
            actionStuff = {
                handler: handleUndeleteUser,
                text: 'Löschauftrag widerrufen',
                successText: 'Löschauftrag zurückgenommen',
            };
        } else if (isPhoneUser) {
            actionStuff = {
                handler: handlePhoneUserUpdateEmail,
                text: 'E-Mail-Adresse aktualisieren',
                successText: undefined,
            };
        } else {
            actionStuff = {
                handler: handlePasswordResetEmail,
                text: '"Passwort Vergessen" E-Mail schicken',
                successText: '"Passwort Vergessen" E-Mail verschickt',
            };
        }

        if (!actionStuff) {
            return null;
        }
        return (
            <Row>
                <UserActionButton
                    onClick={actionStuff.handler}
                    status={user.userActionStatus}
                    disabled={user.loading}
                    children={actionStuff.text}
                    successText={actionStuff.successText}
                />
                <ErrorFeedback apiErrors={user.userActionErrors} />
            </Row>
        );
    }

    const { appliance_stats, user_id } = user.details;

    const information = searchResultsById
        .filter((it) => it.user_id === user_id)
        .reduce((info) => info);

    const disallowed = !isAdmin || user.loading;

    const route: Route =
        searchResults.length > 0 ? Route.SEARCH_USER : Route.SEARCH_USER_ID;

    const searchResultIsB2BUser = information.b2b_user;

    return (
        <Row
            className={classnames(
                'flex-column justify-content-between',
                styles.container
            )}
            id="ManageUser"
        >
            <Row>
                <Button onClick={() => goTo(route)}>Zurück</Button>
            </Row>
            <Row>
                <h2>Kunde</h2>
                <UserInformationTable
                    users={[information]}
                    onSelect={() => null}
                    isAdmin={isAdmin}
                />
            </Row>
            {renderUserMainActions()}
            <Row>
                <UserInformationCard
                    user={information}
                    onEditLaundryRoom={handleChangeLaundryRoom}
                    onEditInvoiceDelivery={handleEditInvoiceDelivery}
                    onEditAllowPlainSepaMandateCreation={
                        handleEditAllowSepaMandateCreation
                    }
                    onEditParallelReservations={handleEditParallelReservations}
                />
            </Row>
            {isAdmin && !searchResultIsB2BUser && (
                <Row>
                    <h2>Unbezahlte Rechnungen</h2>
                    <Table bordered striped>
                        <thead>
                            <tr>
                                <th>Erstellt am</th>
                                <th>Letzte Änderung</th>
                                <th>Rechnungsnummer</th>
                                <th>Land</th>
                                <th>Betrag</th>
                                <th>Zahlungsstatus</th>
                                <th>Zielstatus</th>
                                <th>Aktion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user.details.outstanding_invoices.map((it) => (
                                <tr key={it.invoice_id}>
                                    <td>
                                        {moment(it.creation_time).format(
                                            'DD.MM.YYYY HH:mm:ss'
                                        )}
                                    </td>
                                    <td>
                                        {moment(it.update_time).format(
                                            'DD.MM.YYYY HH:mm:ss'
                                        )}
                                    </td>
                                    <td>{it.invoice_number}</td>
                                    <td>{it.country_code}</td>
                                    <td>{formatMonetaryAmount(it.amount)}</td>
                                    <td>
                                        {translateInvoicePaymentStatus(
                                            it.payment_status
                                        )}
                                        {maybeTranslateBillingType(
                                            it.billing_type
                                        )}
                                    </td>
                                    <td>{it.target_accounting_state}</td>
                                    <td>
                                        <Button
                                            disabled={user.loading}
                                            onClick={() =>
                                                goTo(
                                                    Route.PAY_INVOICE,
                                                    it.invoice_id
                                                )
                                            }
                                        >
                                            BEARBEITEN
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Button
                        onClick={handleShowMoreInvoices}
                        disabled={user.loading}
                        variant={'outline-primary'}
                    >
                        ZU MEHR RECHNUNGEN
                    </Button>
                </Row>
            )}
            <Row>
                <h2>Waschraum Info</h2>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>
                                Waschmaschinen
                                <br />
                                Verfügbar / Vorhanden
                            </th>
                            <th>
                                Trockner
                                <br />
                                Verfügbar / Vorhanden
                            </th>
                            <th>Hinweis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{information.hub_state}</td>
                            <td>
                                {appliance_stats.enabled_washers} /{' '}
                                {appliance_stats.installed_washers}
                            </td>
                            <td>
                                {appliance_stats.enabled_dryers} /{' '}
                                {appliance_stats.installed_dryers}
                            </td>
                            <td>{information.location.note}</td>
                        </tr>
                    </tbody>
                </Table>
                {!searchResultIsB2BUser && (
                    <Button
                        onClick={handleChangeLaundryRoom}
                        disabled={user.loading}
                    >
                        WASCHRAUM ÄNDERN
                    </Button>
                )}
                {isAdmin && !searchResultIsB2BUser && (
                    <Button
                        className="ml-2"
                        onClick={handleChangeLaundryRoomById}
                        disabled={user.loading}
                    >
                        WASCHRAUM ÄNDERN MIT ID
                    </Button>
                )}
            </Row>
            <Row>
                <h2>Buchungen</h2>
                <UserReservationList
                    userId={user.details.user_id}
                    reservations={user.details.last_five_reservations}
                    loading={user.loading}
                />
                <Button
                    onClick={handleShowMoreReservations}
                    disabled={user.loading}
                    variant={'outline-primary'}
                >
                    ZU MEHR BUCHUNGEN
                </Button>
            </Row>
            {!searchResultIsB2BUser && (
                <Row>
                    <h2>Zahlungsmethoden</h2>

                    <>
                        <Table bordered striped>
                            <thead>
                                <tr>
                                    <th>Typ</th>
                                    <th>Zahlungsinformation</th>
                                    <th>Gültig bis</th>
                                    <th> Status</th>
                                    {isAdmin && <th>Fehlerrate</th>}
                                    <th>Wählen</th>
                                    {isAdmin && <th>Löschen</th>}
                                    {isAdmin && <th>Validieren</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {isAdmin &&
                                    user.details.checkout_payment_method &&
                                    renderPaymentMethod(
                                        user.details.checkout_payment_method
                                    )}
                                {user.details.payment_methods.map(
                                    renderPaymentMethod
                                )}
                            </tbody>
                        </Table>
                        <AddPayment userId={user_id} />
                    </>
                </Row>
            )}
            {!searchResultIsB2BUser && (
                <Row>
                    <h2>Transaktionsübersicht</h2>

                    <Table bordered striped>
                        <thead>
                            <tr>
                                <th>Typ</th>
                                <th>Datum</th>
                                <th>Rechnung/Schulden Nr.</th>
                                <th>Zahlungsmittel</th>
                                <th>Betrag</th>
                                <th>Status</th>
                                <th>Land</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user.details.last_ten_financial_transactions.map(
                                (it) => (
                                    <tr key={`ft-${it.transaction_date}`}>
                                        <td>
                                            {translateFinancialTransactionType(
                                                it.type
                                            )}
                                        </td>
                                        <td>
                                            {moment(it.transaction_date).format(
                                                'DD.MM.YYYY'
                                            )}
                                        </td>
                                        <td>
                                            {it.invoice_or_debt_no}{' '}
                                            {it.associated_invoices &&
                                                `(${it.associated_invoices.join(
                                                    ','
                                                )})`}
                                        </td>
                                        <td>
                                            {translatePaymentInformation(
                                                it.payment_information
                                            )}
                                        </td>
                                        <td>
                                            {formatMonetaryAmount(it.amount)}
                                        </td>
                                        <td>
                                            {translateFinancialTransactionStatus(
                                                it.transaction_status
                                            )}
                                        </td>
                                        <td>{it.country_code}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Row>
            )}
            {isAdmin && (
                <Row className={classnames(styles.adminButtonRow)}>
                    <Button onClick={handleForceRealtimeUpdate}>
                        FORCE UPDATE User TO Firebase
                    </Button>
                    <Button
                        disabled={user.loading}
                        variant="danger"
                        onClick={handleDeleteUser}
                    >
                        Delete User
                    </Button>
                </Row>
            )}
            {isAdmin && (
                <Row className={classnames(styles.adminButtonRow)}>
                    <div />
                    <ErrorFeedback apiErrors={user.deleteUserErrors} />
                </Row>
            )}
            <Row>
                <Button onClick={() => goTo(route)}>Zurück</Button>
            </Row>
        </Row>
    );
};

function maybeTranslateBillingType(booking?: InvoiceBillingType) {
    if (!booking) {
        return undefined;
    }
    return '; ' + printInvoiceBillingType(booking);
}

export default connect(
    (store: RootStore) => ({
        user: store.manageUser,
        searchResults: store.searchUser.resultsByEmail,
        searchResultsById: store.searchUser.resultsById,
        isAdmin: store.user.userIsAdmin,
    }),
    {
        selectPaymentMethod,
        validatePaymentMethod: revalidatePaymentMethod,
        openModal,
        goTo,
        forceUpdateUserRealtimeDataMethod,
        deleteUser,
        undeleteUser,
        resendActivationCode,
        sendPasswordResetEmail,
        resetUserActionStatus,
    }
)(ManageUser);
