import React, { ReactChild } from 'react';
import {
    DiscountType,
    InvoiceDetails,
    ReservationForInvoiceDetails,
} from '../../../store/pay-invoice/payInvoiceReducer';
import moment from 'moment';
import { formatMonetaryAmount } from '../../../store/amountAndCurrency';
import styles from '../CancelUserInvoice.module.css';

interface Props {
    invoice: InvoiceDetails;
}

function UserInvoiceDetails({ invoice }: Readonly<Props>) {
    return (
        <table className={styles.invoiceItemList}>
            <thead>
                <tr>
                    <td>Res-ID</td>
                    <td>Maschine</td>
                    <td>Datum</td>
                    <td>Betrag</td>
                </tr>
            </thead>
            <tbody>
                {invoice.reservations.map(renderReservation)}
                {renderSpecials()}
            </tbody>
        </table>
    );

    function renderReservation(reservation: ReservationForInvoiceDetails) {
        const time = moment(reservation.timestamp).format('DD.MM.YYYY - HH:mm');
        const money = formatMonetaryAmount({
            amount: reservation.amount,
            currency: invoice.currency,
        });
        return (
            <tr key={reservation.invoice_item_id}>
                <td className={styles.invoiceItemEntry}>
                    {reservation.reservation_id}
                </td>
                <td className={styles.invoiceItemEntry}>
                    {reservation.short_name}
                </td>
                <td className={styles.invoiceItemEntry}>{time}</td>
                <td className={styles.invoiceItemEntry}>{money}</td>
            </tr>
        );
    }
    function renderSpecials() {
        const result: ReactChild[] = [];
        if (invoice.balance) {
            result.push(
                <tr key={'balance'}>
                    <td className={styles.invoiceItemEntry} />
                    <td className={styles.invoiceItemEntry} />
                    <td className={styles.invoiceItemEntry}>
                        Verwendetes Guthaben
                    </td>
                    <td className={styles.invoiceItemEntry}>
                        {formatMonetaryAmount({
                            amount: invoice.balance,
                            currency: invoice.currency,
                        })}
                    </td>
                </tr>
            );
        }
        if (invoice.discounts) {
            invoice.discounts.forEach((dscnt, i) =>
                result.push(
                    <tr key={`${dscnt.type}${i}`}>
                        <td />
                        <td>{discountTypeToText(dscnt.type)}</td>
                        <td>
                            {formatMonetaryAmount({
                                amount: dscnt.amount,
                                currency: invoice.currency,
                            })}
                        </td>
                    </tr>
                )
            );
        }
        return result;
    }
}

function discountTypeToText(discountType: DiscountType): string {
    switch (discountType) {
        case 'DISCOUNT':
            return 'Ermäßigung';
        case 'VOUCHER':
            return 'Gutschein/Voucher';
    }
    return discountType;
}

export default UserInvoiceDetails;
