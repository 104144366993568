import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags } from '../../http/errors';
import {
    BlindMaintenanceCancelDto,
    BlindMaintenanceStartDto,
    BlindMaintenanceUpdateDto,
    CancelVerificationDto,
    StartVerificationDto,
    ToggleApplianceDto,
} from '../../forms/manage-appliance/ManageApplianceForm';
import { ReactText } from 'react';
import {
    ApplianceControlStatus,
    ApplianceDto,
    ApplianceMaintenanceScheduleDto,
    LocationWithAppliancesDto,
} from './manageApplianceReducer';

export const TOGGLE_APPLIANCE = 'TOGGLE_APPLIANCE';
export const TOGGLE_APPLIANCE_SUCCESS = 'TOGGLE_APPLIANCE_SUCCESS';
export const TOGGLE_APPLIANCE_FAILURE = 'TOGGLE_APPLIANCE_FAILURE';

export const LOAD_APPLIANCE = 'LOAD_APPLIANCE';
export const LOAD_APPLIANCE_SUCCESS = 'LOAD_APPLIANCE_SUCCESS';
export const LOAD_APPLIANCE_FAILURE = 'LOAD_APPLIANCE_FAILURE';

export const LOAD_LOCATION_WITH_APPLIANCES = 'LOAD_LOCATION_WITH_APPLIANCES';
export const LOAD_LOCATION_WITH_APPLIANCES_SUCCESS =
    'LOAD_LOCATION_WITH_APPLIANCES_SUCCESS';

export const START_INSTALLATION_VERIFICATION =
    'START_INSTALLATION_VERIFICATION';
export const START_INSTALLATION_VERIFICATION_SUCCESS =
    'START_INSTALLATION_VERIFICATION_SUCCESS';
export const START_INSTALLATION_VERIFICATION_FAILURE =
    'START_INSTALLATION_VERIFICATION_FAILURE';

export const CANCEL_INSTALLATION_VERIFICATION =
    'CANCEL_INSTALLATION_VERIFICATION';
export const CANCEL_INSTALLATION_VERIFICATION_SUCCESS =
    'CANCEL_INSTALLATION_VERIFICATION_SUCCESS';
export const CANCEL_INSTALLATION_VERIFICATION_FAILURE =
    'CANCEL_INSTALLATION_VERIFICATION_FAILURE';

export const CREATE_MAINTENANCE_SCHEDULE = 'CREATE_MAINTENANCE_SCHEDULE';
export const CREATE_MAINTENANCE_SCHEDULE_SUCCESS =
    'CREATE_MAINTENANCE_SCHEDULE_SUCCESS';
export const CREATE_MAINTENANCE_SCHEDULE_FAILURE =
    'CREATE_MAINTENANCE_SCHEDULE_FAILURE';

export const UPDATE_MAINTENANCE_SCHEDULE = 'UPDATE_MAINTENANCE_SCHEDULE';

export const UPDATE_MAINTENANCE_SCHEDULE_SUCCESS =
    'UPDATE_MAINTENANCE_SCHEDULE_SUCCESS';

export const UPDATE_MAINTENANCE_SCHEDULE_FAILURE =
    'UPDATE_MAINTENANCE_SCHEDULE_FAILURE';

export const DELETE_MAINTENANCE_SCHEDULE = 'DELETE_MAINTENANCE_SCHEDULE';

export const DELETE_MAINTENANCE_SCHEDULE_SUCCESS =
    'DELETE_MAINTENANCE_SCHEDULE_SUCCESS';

export const DELETE_MAINTENANCE_SCHEDULE_FAILURE =
    'DELETE_MAINTENANCE_SCHEDULE_FAILURE';

export const START_BLIND_MAINTENANCE = 'START_BLIND_MAINTENANCE';
export const START_BLIND_MAINTENANCE_SUCCESS =
    'START_BLIND_MAINTENANCE_SUCCESS';
export const START_BLIND_MAINTENANCE_FAILURE =
    'START_BLIND_MAINTENANCE_FAILURE';

export const CANCEL_BLIND_MAINTENANCE = 'CANCEL_BLIND_MAINTENANCE';
export const CANCEL_BLIND_MAINTENANCE_SUCCESS =
    'CANCEL_BLIND_MAINTENANCE_SUCCESS';
export const CANCEL_BLIND_MAINTENANCE_FAILURE =
    'CANCEL_BLIND_MAINTENANCE_FAILURE';

export const UPDATE_BLIND_MAINTENANCE = 'UPDATE_BLIND_MAINTENANCE';
export const UPDATE_BLIND_MAINTENANCE_SUCCESS =
    'UPDATE_BLIND_MAINTENANCE_SUCCESS';
export const UPDATE_BLIND_MAINTENANCE_FAILURE =
    'UPDATE_BLIND_MAINTENANCE_FAILURE';

export const REQUIRE_CLIENT_INSTALLATION = 'REQUIRE_CLIENT_INSTALLATION';
export const REQUIRE_CLIENT_INSTALLATION_SUCCESS =
    'REQUIRE_CLIENT_INSTALLATION_SUCCESS';
export const REQUIRE_CLIENT_INSTALLATION_FAILURE =
    'REQUIRE_CLIENT_INSTALLATION_FAILURE';

export const UPDATE_CONTROL_STATUS = 'UPDATE_CONTROL_STATUS';
export const UPDATE_CONTROL_STATUS_SUCCESS = 'UPDATE_CONTROL_STATUS_SUCCESS';
export const UPDATE_CONTROL_STATUS_FAILURE = 'UPDATE_CONTROL_STATUS_FAILURE';

export const RESET_ACTION_ERRORS = 'RESET_ACTION_ERRORS';

export const MILLIS_PER_HOUR = 3600 * 1000;

export function resetActionErrors() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: RESET_ACTION_ERRORS });
    };
}

export function loadAppliance(
    applianceId: ReactText,
    loadedLocationId?: number | undefined
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_APPLIANCE });
        try {
            const { data } = await RestClient.get<ApplianceDto>(
                `/v1/callcenter/appliances/${applianceId}`
            );
            dispatch({ type: LOAD_APPLIANCE_SUCCESS, payload: { data } });
            if (!loadedLocationId || data.location_id !== loadedLocationId) {
                dispatch({ type: LOAD_LOCATION_WITH_APPLIANCES });
                const locationWithAppliancesResponse =
                    await RestClient.get<LocationWithAppliancesDto>(
                        `/v1/callcenter/manage_appliances/laundry_room/${data.location_id}`
                    );
                dispatch({
                    type: LOAD_LOCATION_WITH_APPLIANCES_SUCCESS,
                    payload: {
                        locationWithAppliances:
                            locationWithAppliancesResponse.data,
                    },
                });
            }
        } catch (error) {
            dispatch({
                type: LOAD_APPLIANCE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function loadLocationWithAppliances(locationId: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_LOCATION_WITH_APPLIANCES });
        try {
            const locationWithAppliancesResponse =
                await RestClient.get<LocationWithAppliancesDto>(
                    `/v1/callcenter/manage_appliances/laundry_room/${locationId}`
                );
            dispatch({
                type: LOAD_LOCATION_WITH_APPLIANCES_SUCCESS,
                payload: {
                    locationWithAppliances: locationWithAppliancesResponse.data,
                },
            });
            const appliances = locationWithAppliancesResponse.data.appliances;
            if (appliances && appliances.length > 0) {
                dispatch({ type: LOAD_APPLIANCE });
                const { data } = await RestClient.get<ApplianceDto>(
                    `/v1/callcenter/appliances/${appliances[0].id}`
                );
                dispatch({ type: LOAD_APPLIANCE_SUCCESS, payload: { data } });
            }
        } catch (error) {
            dispatch({
                type: LOAD_APPLIANCE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function toggleAppliance(data: ToggleApplianceDto) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: TOGGLE_APPLIANCE, payload: {} });
        try {
            await RestClient.post(`/v1/callcenter/appliances/toggle`, {
                appliance_id: data.id,
                comment: data.comment,
                is_enabled: data.isEnabled,
                update_lint_filter_replacement:
                    data.isEnabled && data.updateFilterReplacementTimestamp,
                discharge_pump_incident:
                    !data.isEnabled && data.dischargePumpIncident,
                deactivation_reason: data.isEnabled
                    ? undefined
                    : data.deactivationReason,
            });

            dispatch({
                type: TOGGLE_APPLIANCE_SUCCESS,
                payload: { applianceId: data.id },
            });

            await dispatch(loadAppliance(data.id));
        } catch (error) {
            dispatch({
                type: TOGGLE_APPLIANCE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function hoursToMillis(hours: string): number {
    return Math.round(parseFloat(hours) * MILLIS_PER_HOUR);
}

export function startInstallationVerification(data: StartVerificationDto) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: START_INSTALLATION_VERIFICATION, payload: {} });
        try {
            const durationMillis = hoursToMillis(data.loadingTimeHours);
            await RestClient.post(
                `/v1/callcenter/appliances/${data.id}/verify_installation`,
                {
                    send_report_cp: data.sendReportCp,
                    send_report_cs: data.sendReportCs,
                    comment: data.comment,
                    loading_time_millis: durationMillis,
                }
            );

            dispatch({
                type: START_INSTALLATION_VERIFICATION_SUCCESS,
                payload: { applianceId: data.id },
            });

            await dispatch(loadAppliance(data.id));
        } catch (error) {
            dispatch({
                type: START_INSTALLATION_VERIFICATION_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function cancelInstallationVerification(data: CancelVerificationDto) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CANCEL_INSTALLATION_VERIFICATION, payload: {} });
        try {
            await RestClient.post(
                `/v1/callcenter/appliances/${data.id}/cancel_installation_verification`,
                { comment: data.comment }
            );

            dispatch({
                type: CANCEL_INSTALLATION_VERIFICATION_SUCCESS,
                payload: data.id,
            });

            await dispatch(loadAppliance(data.id));
        } catch (error) {
            dispatch({
                type: CANCEL_INSTALLATION_VERIFICATION_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function deleteMaintenanceSchedule(
    data: ApplianceMaintenanceScheduleDto
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: DELETE_MAINTENANCE_SCHEDULE, payload: {} });
        try {
            await RestClient.delete(
                `/v1/callcenter/appliances/${data.appliance_id}/maintenance_schedules/${data.id}`
            );

            dispatch({
                type: DELETE_MAINTENANCE_SCHEDULE_SUCCESS,
                payload: { applianceId: data.appliance_id },
            });

            await dispatch(loadAppliance(data.appliance_id));
        } catch (error) {
            dispatch({
                type: DELETE_MAINTENANCE_SCHEDULE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function createMaintenanceSchedule(
    data: ApplianceMaintenanceScheduleDto,
    onSuccess: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CREATE_MAINTENANCE_SCHEDULE, payload: {} });
        try {
            await RestClient.post(
                `/v1/callcenter/appliances/${data.appliance_id}/maintenance_schedules`,
                data
            );

            dispatch({
                type: CREATE_MAINTENANCE_SCHEDULE_SUCCESS,
                payload: { applianceId: data.appliance_id },
            });

            await dispatch(loadAppliance(data.appliance_id));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch (error) {
            dispatch({
                type: CREATE_MAINTENANCE_SCHEDULE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function updateMaintenanceSchedule(
    data: ApplianceMaintenanceScheduleDto,
    onSuccess: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_MAINTENANCE_SCHEDULE, payload: {} });
        try {
            await RestClient.put(
                `/v1/callcenter/appliances/${data.appliance_id}/maintenance_schedules`,
                data
            );

            dispatch({
                type: UPDATE_MAINTENANCE_SCHEDULE_SUCCESS,
                payload: { applianceId: data.appliance_id },
            });

            await dispatch(loadAppliance(data.appliance_id));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch (error) {
            dispatch({
                type: UPDATE_MAINTENANCE_SCHEDULE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function startBlindMaintenance(data: BlindMaintenanceStartDto) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: START_BLIND_MAINTENANCE, payload: {} });
        try {
            const durationMillis = hoursToMillis(data.durationHours);
            await RestClient.post(
                `/v1/callcenter/appliances/${data.id}/blind_maintenance`,
                {
                    start_time: data.startTime.getTime(),
                    end_time: data.startTime.getTime() + durationMillis,
                    target_is_enabled: data.targetIsEnabled,
                    comment: data.comment,
                    lint_filter_replacement: data.lintFilterReplacement,
                    reason: data.reason,
                }
            );

            dispatch({
                type: START_BLIND_MAINTENANCE_SUCCESS,
                payload: { applianceId: data.id },
            });

            await dispatch(loadAppliance(data.id));
        } catch (error) {
            dispatch({
                type: START_BLIND_MAINTENANCE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function cancelBlindMaintenance(data: BlindMaintenanceCancelDto) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CANCEL_BLIND_MAINTENANCE, payload: {} });
        try {
            await RestClient.post(
                `/v1/callcenter/appliances/${data.id}/cancel_blind_maintenance`,
                {
                    target_is_enabled: data.targetIsEnabled,
                    comment: data.comment,
                    lint_filter_replacement: data.lintFilterReplacement,
                }
            );

            dispatch({
                type: CANCEL_BLIND_MAINTENANCE_SUCCESS,
                payload: { applianceId: data.id },
            });

            await dispatch(loadAppliance(data.id));
        } catch (error) {
            dispatch({
                type: CANCEL_BLIND_MAINTENANCE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function updateBlindMaintenance(data: BlindMaintenanceUpdateDto) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_BLIND_MAINTENANCE, payload: {} });
        try {
            await RestClient.patch(
                `/v1/callcenter/appliances/${data.id}/blind_maintenance`,
                {
                    start_time: data.startTime && data.startTime.getTime(),
                    end_time: data.endTime && data.endTime.getTime(),
                    target_is_enabled: data.targetIsEnabled,
                }
            );

            dispatch({
                type: UPDATE_BLIND_MAINTENANCE_SUCCESS,
                payload: { applianceId: data.id },
            });

            await dispatch(loadAppliance(data.id));
        } catch (error) {
            dispatch({
                type: UPDATE_BLIND_MAINTENANCE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function startClientInstallation(applianceId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: REQUIRE_CLIENT_INSTALLATION, payload: {} });
        try {
            await RestClient.post(
                `/v1/callcenter/appliances/${applianceId}/client_installation`
            );

            dispatch({
                type: REQUIRE_CLIENT_INSTALLATION_SUCCESS,
                payload: { applianceId },
            });

            await dispatch(loadAppliance(applianceId));
        } catch (error) {
            dispatch({
                type: REQUIRE_CLIENT_INSTALLATION_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function updateApplianceControlStatus(
    applianceId: number,
    controlStatus: ApplianceControlStatus,
    onSuccess: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_CONTROL_STATUS });
        try {
            await RestClient.patch(
                `/v1/callcenter/appliances/${applianceId}/monitoring`,
                {
                    appliance_id: applianceId,
                    control_status: controlStatus,
                }
            );

            dispatch({
                type: UPDATE_CONTROL_STATUS_SUCCESS,
            });

            await dispatch(loadAppliance(applianceId));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch (error) {
            dispatch({
                type: UPDATE_CONTROL_STATUS_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
