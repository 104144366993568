import React, { Fragment, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Form, Row, Spinner } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../ErrorFeedback';
import {
    ApplianceControlStatus,
    ApplianceMonitoring,
    ManageApplianceStore,
} from '../../store/manage-appliances/manageApplianceReducer';
import {
    resetActionErrors,
    updateApplianceControlStatus,
} from '../../store/manage-appliances/manageApplianceActions';
import moment from 'moment';

interface Props {
    manageApplianceStore: ManageApplianceStore;
    data: { monitoring: ApplianceMonitoring };
    close: () => void;
    updateApplianceControlStatus: (
        applianceId: number,
        controlStatus: ApplianceControlStatus,
        onSuccess: () => void
    ) => void;
    resetActionErrors: () => void;
}

interface InputFormData {
    controlStatus: ApplianceControlStatus;
}

function ChangeApplianceControlStatus({
    manageApplianceStore,
    data,
    close,
    updateApplianceControlStatus,
    resetActionErrors,
}: Props) {
    const isLoading = manageApplianceStore.loading;
    useEffect(() => {
        resetActionErrors();
    }, []);

    const initialValues: InputFormData = {
        controlStatus: data.monitoring.control_status,
    };

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Kontrollstatus ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Letzte Messung
                <ul>
                    <li>
                        Zeit: {formatTimestamp(data.monitoring.last_received)}
                    </li>
                    <li>Leistung: {formatPower(data.monitoring.last_watt)}</li>
                    <li>
                        Schützstatus (laut Firmware):{' '}
                        {data.monitoring.last_switch_status
                            ? data.monitoring.last_switch_status
                            : '-'}
                    </li>
                </ul>
                Letzte Warnung
                <ul>
                    <li>
                        Zeit: {formatTimestamp(data.monitoring.warning_time)}
                    </li>
                    <li>
                        Leistung: {formatPower(data.monitoring.warning_watt)}
                    </li>
                    <li>
                        Schützstatus (laut Firmware):{' '}
                        {data.monitoring.warning_switch_status
                            ? data.monitoring.warning_switch_status
                            : '-'}
                    </li>
                </ul>
                Letztes Statusupdate
                <ul>
                    <li>
                        Zeit:{' '}
                        {formatTimestamp(
                            data.monitoring.control_status_updated_time
                        )}
                    </li>
                </ul>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
                <ErrorFeedback apiErrors={manageApplianceStore.actionErrors} />
            </Modal.Body>
        </Fragment>
    );

    function formatPower(power?: number): string {
        if (power === null || power === undefined) {
            return '-';
        }
        return power.toFixed(1) + ' W';
    }

    function formatTimestamp(epochMillis?: number): string {
        if (!epochMillis) {
            return '-';
        }
        return moment(epochMillis).format('DD.MM.YYYY, HH:mm');
    }

    function renderForm(formProps: FormikProps<InputFormData>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group>
                    <Form.Label>Kontrollstatus</Form.Label>
                    <Form.Control
                        as={'select'}
                        name={'controlStatus'}
                        onChange={formProps.handleChange}
                        value={formProps.values.controlStatus}
                    >
                        {Object.values(ApplianceControlStatus).map((it) => (
                            <option value={it} key={it}>
                                {it}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Row className={'mx-0 mt-4'}>
                    <Button
                        onClick={handleCancel}
                        variant="outline-primary"
                        className={'mr-2'}
                    >
                        Abbrechen
                    </Button>
                    <Button disabled={isLoading} type="submit">
                        {isLoading && <Spinner animation={'border'} />}
                        Updaten
                    </Button>
                </Row>
            </Form>
        );
    }

    function handleCancel() {
        close();
    }

    function handleSubmit(
        value: InputFormData,
        actions: FormikActions<InputFormData>
    ) {
        updateApplianceControlStatus(
            data.monitoring.appliance_id,
            value.controlStatus,
            close
        );
        actions.setSubmitting(false);
    }
}

export default connect(
    (state: RootStore) => ({ manageApplianceStore: state.manageAppliance }),
    {
        close: closeModal,
        updateApplianceControlStatus,
        resetActionErrors,
    }
)(ChangeApplianceControlStatus);
