import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { AnyAction } from 'redux-starter-kit';
import RestClient from '../../http/RestClient';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import {FinancialTransactionDetails, InvoiceBillingType} from '../pay-invoice/payInvoiceReducer';

export const LOAD_INVOICES_OF_USER = 'LOAD_INVOICES_OF_USER';
export const LOAD_INVOICES_OF_USER_SUCCESS = 'LOAD_INVOICES_OF_USER_SUCCESS';
export const LOAD_INVOICES_OF_USER_FAILURE = 'LOAD_INVOICES_OF_USER_FAILURE';

export interface InvoicesOfUser {
    user_id: number;
    invoices: Invoice[];
}

export interface Invoice {
    invoice_id: number;
    country_id: number;
    document_status: string;
    payment_status: string;
    accounting_status: string;
    target_accounting_state: string;
    amount: number;
    creation_time: number;
    update_time: number;
    invoice_number: string;
    financial_transaction: FinancialTransactionDetails;
    billing_type?: InvoiceBillingType;
}

export function loadInvoicesOfUserById(userId: number, limit?: number) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        const limitStr = limit ? `?size=${limit}` : '';
        dispatch({ type: LOAD_INVOICES_OF_USER, payload: { userId } });
        try {
            const { data } = await RestClient.get(
                `/v1/callcenter/user_invoices/${userId}${limitStr}`
            );
            dispatch({
                type: LOAD_INVOICES_OF_USER_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: LOAD_INVOICES_OF_USER_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}
