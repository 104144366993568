import React from 'react';
import { Table } from 'react-bootstrap';
import { ApplianceOverviewDto } from '../../store/location/locationReducer';
import { Route } from '../../Router';
import { applianceId } from '../../util/constants';

interface Props {
    appliances: ApplianceOverviewDto[];
    goTo: (route: Route, path?: string, requestParams?: any) => any;
}

export function ApplianceOverviewTable(props: Props) {
    if (!props.appliances || props.appliances.length === 0) {
        return <p>Dieser Waschraum hat keine Maschinen.</p>;
    }
    const hideCursorStyle = {
        cursor: 'auto !important',
    };
    return (
        <Table bordered>
            <thead>
                <tr style={hideCursorStyle}>
                    <th>ID</th>
                    <th>Benennung</th>
                    <th>Control Type</th>
                    <th>Status</th>
                    <th>seit</th>
                    <th>Quelle</th>
                    <th>Kommentar</th>
                </tr>
            </thead>
            <tbody>{props.appliances.map(renderEntry)}</tbody>
        </Table>
    );

    function renderEntry(applianceData: ApplianceOverviewDto) {
        return (
            <tr>
                <td>{applianceData.appliance_id}</td>
                <td>{applianceData.appliance_short_name}</td>
                <td>{applianceData.control_type}</td>
                <td>{applianceData.enabled ? 'Aktiv' : 'Deaktiviert'}</td>
                <td>{renderTimestamp(applianceData.status_since)}</td>
                <td>{applianceData.source}</td>
                <td>{applianceData.reason}</td>
                <td>
                    <button
                        type="button"
                        className={'btn btn-outline-secondary'}
                        onClick={handleEntrySelect}
                    >
                        Details
                    </button>
                </td>
            </tr>
        );

        function renderTimestamp(timestamp?: number) {
            if (!timestamp) {
                return '?';
            } else {
                return new Date(timestamp).toLocaleString('de-DE');
            }
        }

        function handleEntrySelect() {
            const param = new Map();
            param.set(applianceId, applianceData.appliance_id);
            props.goTo(Route.MANAGE_APPLIANCE, undefined, param);
        }
    }
}
