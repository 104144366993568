import { InvoiceItemStatusForRefund } from '../../store/refund-reservation/refundReservationReducer';
import { ReservationBookingLogic } from '../../store/manage-user/manageUserReducer';

export function printInvoicingStatus(
    invoicingStatus?: InvoiceItemStatusForRefund
): string {
    switch (invoicingStatus) {
        case InvoiceItemStatusForRefund.NEW:
            return `${invoicingStatus}`;
        case InvoiceItemStatusForRefund.CANCELED:
            return `${invoicingStatus} - Betrag wird nicht in Rechnung gestellt.`;
        case InvoiceItemStatusForRefund.REFUNDED:
            return `${invoicingStatus} - Betrag wurde als Credits gutgeschrieben.`;
        case InvoiceItemStatusForRefund.INVOICED:
            return `${invoicingStatus} - In Rechnung gestellt.`;
    }
    return invoicingStatus || '-';
}

export function printBookingLogic(
    bookingLogic?: ReservationBookingLogic
): string {
    switch (bookingLogic) {
        case 'PAY2WASH_VOUCHER':
            return `${bookingLogic} - Pay2Wash; Mit Voucher/Gutschein bezahlt.`;
        case 'PAY2WASH':
            return `${bookingLogic} - Pay2Wash`;
        case 'PAY2WASH_ANONYMOUS':
            return `${bookingLogic} - Pay2Wash; Anonyme Nutzung.`;
    }
    return bookingLogic || '-';
}
