import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { searchInvoices } from '../../store/pay-invoice/payInvoiceActions';
import {
    FinancialTransactionDetails,
    PayInvoiceStore,
} from '../../store/pay-invoice/payInvoiceReducer';
import { UserExtendedDetails } from '../../store/manage-user/manageUserReducer';
import { Route } from '../../Router';
import { goTo } from '../../store/router/routerActions';
import { Button, Form } from 'react-bootstrap';
import { Formik, FormikActions, FormikProps } from 'formik';
import moment from 'moment';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './SearchInvoicesScreen.module.css';
import Table from 'react-bootstrap/Table';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { CommunicationState } from '../../store/communicationState';
import { useHistory, useLocation } from 'react-router-dom';
import { RefreshButton } from '../common/RefreshButton';

interface Props {
    invoiceStore: PayInvoiceStore;
    userDetails: UserExtendedDetails | null;

    searchInvoices: (invoiceId: string) => void;
    goTo: (route: Route, param?: string | number) => void;
}

interface SearchTextFormValues {
    searchTerm: string;
}

function valueOrNotAvailable<T>(value: T | undefined): T | string {
    return value === undefined ? 'N/A' : value;
}

function SearchInvoicesScreen({ searchInvoices, ...props }: Readonly<Props>) {
    const history = useHistory();
    const { pathname, search } = useLocation();

    let initialValues: SearchTextFormValues = {
        searchTerm: '',
    };
    const params = new URLSearchParams(search);
    const searchText = params.get('searchText')
        ? String(params.get('searchText'))
        : initialValues.searchTerm;
    const [appliedSearchTerm, setAppliedSearchTerm] = useState(searchText);

    useEffect(() => {
        if (searchText.length > 0) {
            searchInvoices(searchText);
        }
    }, []);
    function handleFormSubmit(
        values: SearchTextFormValues,
        actions: FormikActions<SearchTextFormValues>
    ) {
        history.push(`${pathname}?searchText=${values.searchTerm}`);
        searchInvoices(values.searchTerm);
        setAppliedSearchTerm(values.searchTerm);
        actions.setSubmitting(false);
    }

    function highlightWhenMatchingSearch(value: any) {
        if (String(value) === String(appliedSearchTerm)) {
            return <i className={styles.blink_me}>{value}</i>;
        } else {
            return <>{value}</>;
        }
    }

    function navigateToInvoice(id: number): void {
        props.goTo(Route.CANCEL_USER_INVOICE, id);
    }

    function handleRefresh() {
        if (appliedSearchTerm && appliedSearchTerm.length > 0) {
            searchInvoices(appliedSearchTerm);
        }
    }

    function renderFinancialTransactionInformation(
        financialTransaction?: FinancialTransactionDetails
    ) {
        if (!financialTransaction) {
            return <div>Keine Informationen verfügbar</div>;
        }
        return (
            <>
                <div>Status: {financialTransaction.status}</div>
                <div>
                    Mollie-Methode: {financialTransaction.mollie_method_type}
                </div>
                <div>
                    Letztes Update:{' '}
                    {moment(
                        financialTransaction.status_updated_timestamp
                    ).format('DD.MM.YYYY HH:mm')}
                </div>
            </>
        );
    }

    function renderTable() {
        const invoices =
            props.invoiceStore.invoiceList !== undefined
                ? props.invoiceStore.invoiceList.invoices
                : [];
        if (invoices.length === 0) {
            if (
                props.invoiceStore.communicationState !==
                CommunicationState.SUCCESS
            ) {
                return <></>;
            } else {
                return <>Keine Ergebnisse</>;
            }
        }
        return (
            <Table bordered hover className={styles.table}>
                <thead>
                    <tr className={styles.header}>
                        <th>ID</th>
                        <th>Nutzer-Id</th>
                        <th>Land-ID</th>
                        <th>Kundennummer</th>
                        <th>Rechnungsnummer</th>
                        <th>Rechnungsdatum</th>
                        <th>Sendestatus</th>
                        <th>Bezahlstatus</th>
                        <th>Buchhaltungsstatus</th>
                        <th>Zielstatus</th>
                        <th>Rechnungstyp</th>
                        <th>Abrechnungstyp</th>
                        <th>Transaktionsinformationen</th>
                    </tr>
                </thead>
                <tbody className={styles.clickable}>
                    {invoices.map((it) => {
                        return (
                            <tr
                                key={it.id}
                                className={styles.body}
                                onClick={() => navigateToInvoice(it.id)}
                            >
                                <td>
                                    {highlightWhenMatchingSearch(
                                        valueOrNotAvailable(it.id)
                                    )}
                                </td>
                                <td>
                                    {highlightWhenMatchingSearch(
                                        valueOrNotAvailable(it.user_id)
                                    )}
                                </td>
                                <td>{it.invoice_country_id}</td>
                                <td>
                                    {highlightWhenMatchingSearch(
                                        valueOrNotAvailable(it.customer_id)
                                    )}
                                </td>
                                <td>
                                    {highlightWhenMatchingSearch(
                                        it.invoice_number
                                    )}
                                </td>
                                <th>
                                    {moment(it.invoice_time).format(
                                        'DD.MM.YYYY HH:mm'
                                    )}
                                </th>
                                <td>{it.status}</td>
                                <td>{it.payment_status}</td>
                                <td>{it.accounting_status}</td>
                                <td>{it.target_accounting_state}</td>
                                <td>{it.type}</td>
                                <td>{valueOrNotAvailable(it.billing_type)}</td>
                                <td>
                                    {renderFinancialTransactionInformation(
                                        it.financial_transaction
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
                {(formProps: FormikProps<SearchTextFormValues>) => (
                    <Form noValidate onSubmit={formProps.handleSubmit}>
                        <ErrorFeedback apiErrors={props.invoiceStore.errors} />

                        <h2>Rechnungssuche</h2>

                        <Form.Group
                            controlId="formGroupSearch"
                            className={styles.searchBar}
                        >
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    name="searchTerm"
                                    className={styles.input}
                                    value={formProps.values.searchTerm}
                                    placeholder={
                                        'Rechnungssuche über ID, Kundennummer, Nutzer-ID, Rechnungsnummer, etc.'
                                    }
                                    onChange={formProps.handleChange}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="outline-dark"
                                        type="submit"
                                        disabled={
                                            formProps.isSubmitting ||
                                            formProps.values.searchTerm
                                                .length === 0
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className={styles.icon}
                                        />
                                    </Button>
                                    <RefreshButton
                                        onRefresh={handleRefresh}
                                    ></RefreshButton>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                )}
            </Formik>
            {renderTable()}
        </>
    );
}

export default connect(
    (state: RootStore) => ({
        invoiceStore: state.payInvoice,
        userDetails: state.manageUser.details,
    }),
    { searchInvoices, goTo }
)(SearchInvoicesScreen);
