import {
    PaymentMethod,
    PaymentMethodCumulativeType,
    PaymentMethodStatus,
    PaymentMethodType,
} from '../../../store/manage-user/manageUserReducer';

export function renderPaymentMethodStatus(pm: PaymentMethod): string {
    if (pm.type === PaymentMethodType.checkout) {
        return translateCheckoutPaymentMethod(pm);
    } else {
        const info = maybeRenderCumulativeInfo(pm);

        if (info) {
            return translatePaymentStatus(pm.status) + info;
        } else {
            return translatePaymentStatus(pm.status);
        }
    }
}

function translatePaymentStatus(status: string) {
    switch (status) {
        case PaymentMethodStatus.EXPIRED:
            return 'Abgelaufen';
        case PaymentMethodStatus.VERIFIED:
        case PaymentMethodStatus.TRUSTED:
            return 'Verifiziert';
        case PaymentMethodStatus.INVALID:
            return 'Ungültig';
        case PaymentMethodStatus.TRIAL:
        case PaymentMethodStatus.UNVERIFIED:
            return 'In Verifizierung';
        default:
            return status;
    }
}

function translateCheckoutPaymentMethod(pm: PaymentMethod): string {
    switch (pm.status) {
        case PaymentMethodStatus.VERIFIED:
            return 'Verifiziert';
        case PaymentMethodStatus.TRUSTED:
            return 'Verifiziert (Monatliche Rechnung)';
        default:
            return pm.status;
    }
}

function maybeRenderCumulativeInfo(pm: PaymentMethod) {
    switch (pm.cumulative_type) {
        case PaymentMethodCumulativeType.CUMULATIVE_PAYMENT_ACCEPTED:
            return ' (Monatliche Rechnung: an)';
        case PaymentMethodCumulativeType.CUMULATIVE_PAYMENT_NOT_ACCEPTED:
            return ' (Monatliche Rechnung: aus)';
        case PaymentMethodCumulativeType.CUMULATIVE_PAYMENT_NOT_AVAILABLE:
        default:
            return null;
    }
}
