import React, { FunctionComponent, ReactNode } from 'react';
import { Invoice } from '../../store/manage-user/manageInvoicesOfUserActions';
import { Route } from '../../Router';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { connect } from 'react-redux';
import { goTo } from '../../store/router/routerActions';
import { FinancialTransactionDetails } from '../../store/pay-invoice/payInvoiceReducer';
import { printInvoiceBillingType } from '../../forms/refund-reservation/InvoiceUtil';

interface Props {
    invoices: Invoice[];
    loading: boolean;
}

interface PropsWithFunctions extends Props {
    goTo: (route: Route, param?: string | number) => void;
}

const UserInvoiceList: FunctionComponent<PropsWithFunctions> = ({
    goTo,
    ...props
}) => {
    return (
        <Table bordered striped>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Land-ID</th>
                    <th>Nummer</th>
                    <th>Betrag</th>
                    <th>Dokumentenstatus</th>
                    <th>Zahlungsstatus</th>
                    <th>Abrechnungsstatus</th>
                    <th>Zielstatus</th>
                    <th>Erstellt</th>
                    <th>Aktualisiert</th>
                    <th>Transaktionsstatus</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{props.invoices.map(renderInvoice)}</tbody>
        </Table>
    );

    function renderInvoice(it: Invoice) {
        return (
            <tr key={it.invoice_id}>
                <td>{it.invoice_id}</td>
                <td>{it.country_id}</td>
                <td>{it.invoice_number}</td>
                <td>{it.amount}</td>
                <td>{it.document_status}</td>
                <td>{it.payment_status}</td>
                <td>{it.accounting_status}</td>
                <td>{it.target_accounting_state}</td>
                <td>
                    {moment(it.creation_time).format('DD.MM.YYYY HH:mm:ss')}
                </td>
                <td>
                    {moment(it.creation_time).format('DD.MM.YYYY HH:mm:ss')}
                </td>
                <td>
                    {renderFinancialTransactionInformation(
                        it,
                        it.financial_transaction
                    )}
                </td>
                <td>{renderCancelSection(it)}</td>
            </tr>
        );
    }

    function renderCancelSection(invoice: Invoice): ReactNode {
        if (['CANCELED'].includes(invoice.target_accounting_state)) {
            return <Button disabled>BEREITS STORNIERT</Button>;
        }
        if (['FILED', 'FILING'].includes(invoice.accounting_status)) {
            return (
                <Button
                    disabled={props.loading}
                    onClick={() =>
                        goTo(Route.CANCEL_USER_INVOICE, invoice.invoice_id)
                    }
                >
                    STORNIEREN
                </Button>
            );
        }
        return '';
    }
};

function renderFinancialTransactionInformation(
    invoice: Invoice,
    financialTransaction?: FinancialTransactionDetails
) {
    if (!financialTransaction) {
        return <div>Keine Informationen verfügbar</div>;
    }
    return (
        <>
            <div>Status: {financialTransaction.status}</div>
            {!!invoice.billing_type && (
                <div>{printInvoiceBillingType(invoice.billing_type)}</div>
            )}
            <div>Mollie-Methode: {financialTransaction.mollie_method_type}</div>
            <div>
                Letztes Update:{' '}
                {moment(financialTransaction.status_updated_timestamp).format(
                    'DD.MM.YYYY HH:mm'
                )}
            </div>
        </>
    );
}

export default connect(() => ({}), { goTo })(UserInvoiceList);
